import {
  GameProvider_BnG,
  GameProvider_EvoPlay,
  GameProvider_Netent,
  GameProvider_OneTouch,
  GameProvider_Pinnacle,
  GameProvider_Playson,
  GameProvider_Spinomenal,
  GameProvider_YGGDRASIL,
  GameProvider_betsoft,
  GameProvider_biggaming,
  GameProvider_evolution,
  GameProvider_ezugi,
  GameProvider_habanero,
  GameProvider_hacksaw,
  GameProvider_jdb,
  GameProvider_jili,
  GameProvider_joker,
  GameProvider_kingmaker,
  GameProvider_live22,
  GameProvider_m8bet,
  GameProvider_microgaming,
  GameProvider_nolimitcity,
  GameProvider_onextwogaming,
  GameProvider_playnGO,
  GameProvider_playtech,
  GameProvider_pragmaticplay,
  GameProvider_quickspin,
  GameProvider_redtiger,
  GameProvider_relaxgaming,
  GameProvider_sexybaccarat,
  GameProvider_spadegaming,
  GameProvider_superspade,
  GameProvider_sv388,
  GameProvider_wazdan,
  GameProvider_youlian,
} from '../../images';

export const providerList2 = [
  // {
  //   name: 'superspade',
  //   icon: GameProvider_superspade,
  // },
  /*  {
    name: 'spadegaming',
    icon: GameProvider_spadegaming,
  }, */
  {
    name: 'sexybaccarat',
    icon: GameProvider_sexybaccarat,
  },
  {
    name: 'redtiger',
    icon: GameProvider_redtiger,
  },
  {
    name: 'quickspin',
    icon: GameProvider_quickspin,
  },
  {
    name: 'pragmaticplay',
    icon: GameProvider_pragmaticplay,
  },
  // {
  //   name: 'playtech',
  //   icon: GameProvider_playtech,
  // },
  /* {
    name: 'playnGO',
    icon: GameProvider_playnGO,
  }, */
  // {
  //   name: 'microgaming',
  //   icon: GameProvider_microgaming,
  // },
  // {
  //   name: 'hacksaw',
  //   icon: GameProvider_hacksaw,
  // },

  {
    name: 'habanero',
    icon: GameProvider_habanero,
  },
  {
    name: 'kingmaker',
    icon: GameProvider_kingmaker,
  },
  // {
  //   name: 'EvoPlay',
  //   icon: GameProvider_EvoPlay,
  // },
  // {
  //   name: 'YGGDRASIL',
  //   icon: GameProvider_YGGDRASIL,
  // },
  // {
  //   name: 'Spinomenal',
  //   icon: GameProvider_Spinomenal,
  // },
  // {
  //   name: 'Playson',
  //   icon: GameProvider_Playson,
  // },
  /*   {
    name: 'evolution',
    icon: GameProvider_evolution,
  }, */
  {
    name: 'pinnacle',
    icon: GameProvider_Pinnacle,
  },
];

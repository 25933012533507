/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { countiesCode } from '../constant/countriesCode';
import {
  ChevronRight,
  TeamAway,
  TeamHome,
} from '../../images';
import sportTypeList from '../constant/sportTypeList';
import Carousel from './Carousel';
import { SettingActions } from '../reducers/settingReducer';
import { getGameCred } from '../../api/getGameCred';
import { getBrand } from '../../api/getBrand';
import { LayoutContext } from '../contexts/LayoutContextContainer';

function SportCollapse({
  id,
  items,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { setToPath } = useContext(LayoutContext);

  const accessToSport = async (hasPath, demo) => {
    const brandInfo = await getBrand(process.env.SPORT_ID);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    if (demo) {
      router.push(`/game_website?brandId=${'658a2e8decc17606d6055c36'}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
    } else {
      const gameInfo = await getGameCred({ brandId: process.env.SPORT_ID });

      if (gameInfo?.error) {
        if (!gameInfo?.error.includes('jwt')) {
          dispatch(
            SettingActions.setAlert([
              {
                msg: gameInfo?.error,
              },
            ]),
          );
        }
        return false;
      }

      const gameProfileId = gameInfo?.data?.data?.[0]._id;
      router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
    }
  };

  const handleToGame = (x) => {
    const modifiedString = x.link.replace(/\/match_\d+/, '');
    if (Cookies.get('accessToken')) {
      accessToSport(modifiedString);
    } else {
      setToPath(modifiedString);
      accessToSport(modifiedString, true);
    }
  };

  return (
    <div className='mb-2 mb-8'>
      <div className='flex justify-between items-end mb-2'>
        <div className='flex items-center justify-between gap-1.5'>
          {
            items?.[0]?.sport_name
            && (
              <Image

                src={(sportTypeList.find((x) => (items?.[0]?.sport_name.toLowerCase()).includes(x.name.toLowerCase())))?.icon?.src}
                className='mr-1.5'
                width={25}
                height={25}
                alt='Sport'
                placeholder='empty'
              />
            )
          }
          <p className='text-lg font-medium'>{items?.[0]?.sport_name}</p>
        </div>
        <div
          className='flex items-center hover:underline cursor-pointer'
          onClick={() => {
            if (Cookies.get('accessToken')) {
              accessToSport('/calendar/upcoming');
            } else {
              setToPath('/calendar/upcoming');
              accessToSport('/calendar/upcoming', true);
            }
          }}
        >
          <p className='text-small font-medium mr-1.5'>Upcoming Events</p>
          <div className='leading-none' style={{ width: 6 }}>
            <Image src={ChevronRight} alt='' width={6} height={9} placeholder='empty' />
          </div>
        </div>
      </div>
      {
        id
        && (
          <Carousel
            id={`${id}-sport-collapse`}
            className=''
            items={(
              <>
                {
                  items?.length > 0 && items.map((x, i) => (
                    <div
                      key={`betradar-sport-${x.id}`}
                      className='rounded-lg overflow-hidden flex-item-sport bg-white'
                    >
                      <div
                        className='head px-2 py-1 border-b-2 flex items-center'
                        style={{
                          borderColor: '#EDEDED',
                          height: '1.8rem',
                        }}
                      >
                        {
                          x.category_name === 'International'
                            ? <Image className='inline-block' src='https://sportsbet.esportings.com/static/images/flags/world.svg' alt='International' placeholder='empty' width={12} height={12} />
                            : (countiesCode.find((country) => country.name?.toLowerCase() === x.category_name?.toLowerCase())?.emoji
                              ? (
                                <i className='text-sm'>
                                  {countiesCode.find((country) => country.name?.toLowerCase() === x.category_name?.toLowerCase())?.emoji}
                                </i>
                              )
                              : (
                                <Image className='inline-block' src='https://sportsbet.esportings.com/static/images/flags/world.svg' width={12} alt='International' height={12} placeholder='empty' />
                              ))
                        }
                        <p
                          className='cut-text-sport-match text-3xs md:text-2xs font-normal inline-block hover:underline cursor-pointer'
                          onClick={() => handleToGame(x)}
                        >
                          {
                            `${x.tournament_name} - ${x.category_name}`
                          }
                        </p>
                      </div>
                      <div className='body p-2'>
                        <div className='capitalize flex items-center' />
                        <div className='grid grid-cols-3 flex items-center pt-2'>
                          <div className='p-2 text-center h-full'>
                            <div className='mb-1 rounded-xl inner-shadow overflow-hidden p-4 flex items-center justify-center bg-custom h-12 w-12 md:h-16 md:w-16 m-auto'>
                              <Image src={TeamHome} alt='Home Team' width={32} height={32} placeholder='empty' />

                            </div>
                            <p className='font-normal text-2xs cut-text-soccer'>
                              {x.team_home_name}
                            </p>
                          </div>
                          <div className='p-2 text-center'>
                            <p className='leading-none'>
                              {moment(x.start_time).format('MMM DD')}
                            </p>
                            <p className='font-normal capitalize text-lg'>
                              {moment(x.start_time).format('HH:mm')}
                            </p>
                          </div>
                          <div className='p-2 text-center h-full'>
                            <div className='mb-1 rounded-xl inner-shadow overflow-hidden p-4 flex items-center justify-center bg-custom h-12 w-12 md:h-16 md:w-16 m-auto'>
                              <Image src={TeamAway} alt='Away Team' width={32} height={32} placeholder='empty' />
                            </div>
                            <p className='font-normal text-2xs cut-text-soccer'>
                              {x.team_away_name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='footer flex gap-2 px-4 pb-4'>
                        <div
                          className='w-full p-1 md:p-2 text-center rounded cursor-pointer'
                          style={x?.p1 >= x?.p2 ? { backgroundColor: '#28AE60', color: 'black' } : { backgroundColor: '#EDEDED' }}
                          onClick={() => handleToGame(x)}
                        >
                          <p className='font-medium'>
                            {x?.p1}
                          </p>
                        </div>
                        {
                          x?.x
                          && (
                            <div
                              className='w-full p-1 md:p-2 text-center rounded cursor-pointer'
                              style={x?.p1 >= x?.p2 ? { backgroundColor: '#28AE60', color: 'black' } : { backgroundColor: '#EDEDED' }}
                              onClick={() => handleToGame(x)}
                            >
                              <p className='font-medium'>
                                {x?.x}
                              </p>
                            </div>
                          )
                        }
                        <div
                          className='w-full p-1 md:p-2 text-center rounded cursor-pointer'
                          style={x?.p2 > x?.p1 ? { backgroundColor: '#28AE60', color: 'black' } : { backgroundColor: '#EDEDED' }}
                          onClick={() => handleToGame(x)}
                        >
                          <p className='font-medium'>
                            {x?.p2}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </>
            )}
          />
        )
      }
    </div>
  );
}

export default SportCollapse;

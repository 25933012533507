const axios = require('axios');

const getSportMatches = (data) => axios
  .request({
    method: 'GET',
    // url: `${ENDPOINT}/v1/sports/matches`,
    url: 'https://api2-sportsbet.esportings.com/static/v1/widgets/popular-events?siteId=812&language=en',
    data,
  })
  .then((response) => response?.data || {})
  .catch((error) => error);

module.exports = {
  getSportMatches,
};

const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getAnnouncement = (params) => axios
  .post(
    `${ENDPOINT}/v1/announcement`,
    {
      branchId: process.env.BRANCH_ID,
      ...params,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  .then((response) => response)
  .catch((error) => {
    console.log({ error });
  });
// error);

module.exports = {
  getAnnouncement,
};

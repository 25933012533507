import {
  SnookerIcon,
  BasketballIcon,
  BaseballIcon,
  CricketIcon,
  EsportIcon,
  HandballIcon,
  SoccerIcon,
  PoliticsIcon,
  VolleyballIcon,
  TennisIcon,
  BadmintonIcon,
  MmaIcon,
  RugbyLeagueIcon,
  RubyUnionIcon,
  GolfIcon,
  WaterPoloIcon,
  FootBallIcon,
  BoxingIcon,
  HockeyIcon,
  KabaddiIcon,
} from '../../images';

export default [
  {
    name: 'Badminton',
    icon: BadmintonIcon,
    backgroundColor: '',
  },
  {
    name: 'Baseball',
    icon: BaseballIcon,
    backgroundColor: '',
  },
  {
    name: 'Basketball',
    icon: BasketballIcon,
    backgroundColor: '#FF9900',
  },
  {
    name: 'Boxing',
    icon: BoxingIcon,
    backgroundColor: '',
  },
  {
    name: 'Cricket',
    icon: CricketIcon,
    backgroundColor: '#8000FF',
  },
  {
    name: 'E Sports',
    icon: EsportIcon,
    backgroundColor: '',
  },
  {
    name: 'Football',
    icon: FootBallIcon,
    backgroundColor: '#0075FF',
  },
  {
    name: 'Golf',
    icon: GolfIcon,
    backgroundColor: '',
  },
  {
    name: 'Handball',
    icon: HandballIcon,
    backgroundColor: '',
  },
  {
    name: 'Hockey',
    icon: HockeyIcon,
    backgroundColor: '#0075FF',
  },
  {
    name: 'MMA',
    icon: MmaIcon,
    backgroundColor: '',
  },
  {
    name: 'Politics',
    icon: PoliticsIcon,
    backgroundColor: '',
  },
  {
    name: 'Rugby League',
    icon: RugbyLeagueIcon,
    backgroundColor: '',
  },
  {
    name: 'Rugby Union',
    icon: RubyUnionIcon,
    backgroundColor: '',
  },
  {
    name: 'Snooker',
    icon: SnookerIcon,
    backgroundColor: '',
  },
  {
    name: 'Soccer',
    icon: SoccerIcon,
    backgroundColor: '#FF9900',
  },
  {
    name: 'Tennis',
    icon: TennisIcon,
    backgroundColor: '#8000FF',
  },
  {
    name: 'Volleyball',
    icon: VolleyballIcon,
    backgroundColor: '',
  },
  {
    name: 'Water Polo',
    icon: WaterPoloIcon,
    backgroundColor: '',
  },
  {
    name: 'Kabaddi',
    icon: KabaddiIcon,
    backgroundColor: '',
  },
];

import {
  GameProvider_Allbet,
  GameProvider_BnG,
  GameProvider_EvoPlay,
  GameProvider_Netent,
  GameProvider_OneTouch,
  GameProvider_Playson,
  GameProvider_Spinomenal,
  GameProvider_YGGDRASIL,
  GameProvider_betsoft,
  GameProvider_biggaming,
  GameProvider_evolution,
  GameProvider_ezugi,
  GameProvider_habanero,
  GameProvider_hacksaw,
  GameProvider_jdb,
  GameProvider_jili,
  GameProvider_joker,
  GameProvider_kingmaker,
  GameProvider_live22,
  GameProvider_m8bet,
  GameProvider_microgaming,
  GameProvider_nolimitcity,
  GameProvider_onextwogaming,
  GameProvider_playnGO,
  GameProvider_playtech,
  GameProvider_pragmaticplay,
  GameProvider_quickspin,
  GameProvider_redtiger,
  GameProvider_relaxgaming,
  GameProvider_sexybaccarat,
  GameProvider_spadegaming,
  GameProvider_superspade,
  GameProvider_sv388,
  GameProvider_wazdan,
  GameProvider_youlian,
} from '../../images';

export const providerList1 = [
  {
    name: 'jili',
    icon: GameProvider_jili,
  },
  {
    name: 'jdb',
    icon: GameProvider_jdb,
  },
  // {
  //   name: 'BnG',
  //   icon: GameProvider_BnG,
  // },
  // {
  //   name: 'onextwogaming',
  //   icon: GameProvider_onextwogaming,
  // },
  {
    name: 'ezugi',
    icon: GameProvider_ezugi,
  },
  /*   {
    name: 'live22',
    icon: GameProvider_live22,
  }, */
  // {
  //   name: 'betsoft',
  //   icon: GameProvider_betsoft,
  // },
  {
    name: 'youlian',
    icon: GameProvider_youlian,
  },
  // {
  //   name: 'relaxgaming',
  //   icon: GameProvider_relaxgaming,
  // },
  {
    name: 'nolimitcity',
    icon: GameProvider_nolimitcity,
  },
  {
    name: 'Netent',
    icon: GameProvider_Netent,
  },
  // {
  //   name: 'sv388',
  //   icon: GameProvider_sv388,
  // },
  // {
  //   name: 'm8bet',
  //   icon: GameProvider_m8bet,
  // },
  {
    name: 'joker',
    icon: GameProvider_joker,
  },
  // {
  //   name: 'OneTouch',
  //   icon: GameProvider_OneTouch,
  // },
  {
    name: 'biggaming',
    icon: GameProvider_biggaming,
  },
  // {
  //   name: 'wazdan',
  //   icon: GameProvider_wazdan,
  // },
];

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Image from 'next/image';
import Marquee from 'react-fast-marquee';
import { providerList2 } from '../constant/providerList2';
import { providerList1 } from '../constant/providerList1';

function PartnerSection() {
  return (
    <div>
      <div className='  w-full my-2 rounded-xl'>
        <div className='h-full pt-3 rounded-xl align-center w-full'>
          <div className='relative'>
            <div className='absolute w-full h-full '>
              <br />
            </div>
            <div className='z-0 w-full md:block hidden overflow-hidden'>

              <Marquee
                className='flex'
                gradient={false}
                speed={10}
              >
                {providerList1.concat(providerList2).map((i, index) => (
                  <div key={`partner-${index}`} className='mx-5'>
                    <Image alt='' layout='fill' src={i.icon.src} placeholder='empty' />
                  </div>
                ))}
              </Marquee>
            </div>
            <div className='z-0 w-full md:hidden block overflow-hidden'>
              <Marquee
                className='flex mb-3'
                gradient={false}
                speed={10}
              >
                {providerList1.map((i, index) => (
                  <div key={`partner-${index}`} className='mx-5'>
                    <Image alt='' layout='fill' src={i.icon.src} placeholder='empty' />
                  </div>
                ))}
              </Marquee>

              <Marquee
                className='flex'
                gradient={false}
                speed={10}
                direction='right'
              >
                {providerList2.map((i, index) => (
                  <div key={`partner-${index}`} className='mx-5'>
                    <Image alt='' layout='fill' src={i.icon.src} placeholder='empty' />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerSection;

import { ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState, useEffect } from 'react';

function BackToTopButton() {
  const scrollToTop = () => {
    const topElement = document.getElementById('top');
    topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className='fixed bottom-4 z-50 right-12'>

      <Button
        onClick={scrollToTop}
        className='bg-customgreen text-white font-bold pb-2 pt-2 px-2.5 rounded-full'
      >
        <ArrowUpOutlined style={{ fontSize: '24px' }} />
      </Button>

    </div>
  );
}

export default BackToTopButton;
